$.fn.lib_nl_google_map = function(event,data) {
    return this.each(function() {
        var $this = $(this);
        if (!$this.length) {
            return false;
        }
        if (event == 'init') {
            nl_google_map_init();
        }
        else if (event == 'markers_add') {
            nl_google_map_markers_add_method();
        }

        else if (event == 'markers_remove') {
            nl_google_map_markers_remove_method();
        }

        else if (event == 'markers_reload') {
            nl_google_map_markers_remove_method();
            nl_google_map_markers_add_method();
        }

        else if (event == 'position') {
            nl_google_map_position_method(data);
        }

        function nl_google_map_init() {
            var $zoom = $this.data("zoom"),
                $coords = $this.data('coords').split(/[\s,]+/),
                $markers = $this.data("markers"),
                $scroll = false,
                $markers_temp = [],
                $map_options = {
                    zoom: $zoom,
                    scrollwheel: false,
                    draggable: false,
                    mapTypeControl: false,
                    disableDefaultUI: false,
                    clicable: false,
                    center: {
                        lat: parseFloat($coords[0]), lng: parseFloat($coords[1])
                    }
                };

            var $map = new google.maps.Map($this[0], $map_options);


            if ($this.is("[data-scroll]")) {
                $scroll = true;
            }

            google.maps.event.addListener($map, 'click', function () {
                $map.setOptions({
                    scrollwheel: $scroll, draggable: true
                });
            });

            function nl_google_map_position(data) {
                var $location = data.split(/[\s,]+/);
                var latLng = new google.maps.LatLng($location[0],$location[1]);
                $map.panTo(latLng);
                $map.setZoom(17);
            }
            function nl_google_map_markers_add() {
                $markers = $this.data("markers");
                var $marker_img = $this.data("marker");
                if ($this.filter("[data-marker]").length) {
                    var $main_marker_position = new google.maps.LatLng(parseFloat($coords[0]), parseFloat($coords[1]));
                    var $main_marker_image = {
                        url: $marker_img,
                        size: new google.maps.Size(190, 109),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(48, 109)
                    };

                    var $main_marker = new google.maps.Marker({
                        position: $main_marker_position,
                        map: $map,
                        icon: $main_marker_image
                    });
                }
                if ($this.filter("[data-markers]").length){
                    var $bounds = new google.maps.LatLngBounds();
                    var $infoWindow = new google.maps.InfoWindow({
                        content: "",
                        maxWidth: 210
                    });
                    if ($this.filter("[data-marker]").length) {
                        $markers_temp.push($main_marker);
                        $bounds.extend($main_marker_position);
                    }
                    for (var i = 0; i < $markers.length; i++) {
                        var $marker = $markers[i],
                            $position = new google.maps.LatLng($marker.lat, $marker.lng),
                            $image = {
                                path: 'M265.1,0.7C155.8,0.7,67.2,89,67.2,198c0,150.4,160.2,313.4,198.6,313.4C304.1,511.3,463,348.4,463,198  C463,89,374.4,0.7,265.1,0.7z M265.1,289.4c-50.3,0-91-40.6-91-90.7c0-50.1,40.7-90.7,91-90.7c50.2,0,91,40.6,91,90.7  C356.1,248.8,315.4,289.4,265.1,289.4z',
                                fillColor: '#ff8623',
                                strokeColor: '#333',
                                strokeOpacity: 0.3,
                                strokeWeight: 1,
                                fillOpacity: 1,
                                scale: 0.1,
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(256, 512)
                            },
                            $icon = new google.maps.Marker({
                                position: $position,
                                map: $map,
                                icon: $image,
                                title: $marker.name,
                                zIndex: $marker.zIndex,
                                infoWindow: {
                                    img: $marker.img,
                                    info_icon: $marker.info_icon,
                                    info: $marker.info
                                }
                            });

                        $markers_temp.push($icon);
                        $bounds.extend($position);

                        function showInfo($marker) {
                            return function () {
                                var $content = "<div class='part_ui_info'><div class='elm_image'><img src='"+$marker.infoWindow.img+"'/></div><div class='elm_row'><div class='col'><h4>"+$marker.title+"</h4></div><div class='col'><div class='elm_info'><i class='icon "+$marker.infoWindow.info_icon+"'></i><i>"+$marker.infoWindow.info+"</i></div></div></div></div>";
                                $map.panTo($marker.position);
                                $infoWindow.setContent($content);
                                $infoWindow.open($map,$marker);
                            }
                        }

                        google.maps.event.addListener($icon, 'click', showInfo($icon));

                        google.maps.event.addListener($infoWindow, 'domready', function() {
                            $('.part_ui_info').closest('.gm-style-iw').parent().addClass('gm-custom-iw');
                        });
                    }

                    google.maps.event.addListenerOnce($map, 'bounds_changed', function() {
                        if(this.getZoom() > 13) {
                            if ($(window).width() < 960) {
                                this.setZoom(13);
                            } else {
                                this.setZoom(14);
                            }
                        }
                    });

                    $map.fitBounds($bounds);
                }

                function nl_google_map_markers_remove() {
                    for (var i = 0; i < $markers_temp.length; i++) {
                        $markers_temp[i].setMap(null);
                    }

                    $markers_temp = [];
                }
                window.nl_google_map_markers_remove_method = nl_google_map_markers_remove;
            }
            window.nl_google_map_markers_add_method = nl_google_map_markers_add;
            window.nl_google_map_position_method = nl_google_map_position;
        }
    });
};