(function($) {
    var $fn = $(".comp_visual, .comp_spotlight");

    $fn.each(function () {
        let el = $(this),
            $slider = el.find("[data-visual-slider]"),
            $slider_caption = el.find("[data-visual-caption]"),
            $slider_autoplay = $slider.data("visual-slider");

        var $autoplay = true;
        if ($slider_autoplay === "0" || $slider_autoplay === "") {
            $autoplay = false;
        }

        var $slider_for = $slider,
            $slider_caption_for = $slider_caption;

        if ($slider_caption.children().length < 1 || $slider.children().length < 1) {
            $slider_caption_for = null;
            $slider_for = null;
        }

        if ($slider.children().length > 1) {
            $slider.slick({
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                autoplay: $autoplay,
                autoplaySpeed: $slider_autoplay,
                arrows: false,
                pauseOnHover: false,
                asNavFor: $slider_caption_for,
                dots: true
            });
            $slider.on('beforeChange', function(){
                $(this).find(".slick-active").next().find("img").addClass("lazyload");
            });
            $slider.on('afterChange', function(){
                $(this).find(".slick-active").find("img").addClass("lazyload");
            });
        }

        if ($slider_caption.children().length > 1) {
            $slider_caption.slick({
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                arrows: false,
                dots: false,
                asNavFor: $slider_for,
                adaptiveHeight: true
            });
        }
    });
})(jQuery);